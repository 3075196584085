import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ShowMoreText from 'react-show-more-text'
import Iframe from "react-iframe"
import OrderForm from "../components/order-form"
import "./contacts.scss"
import { window } from "browser-monads"
import { YMInitializer } from "react-yandex-metrika"
import Img from "gatsby-image"
import DesignerImgOne from "../../static/images/1.jpg"
import DesignerImgTwo from "../../static/images/2.jpg"
import DesignerImgThree from "../../static/images/3.jpg"
import { SlideTestimonial } from "react-materialui-testimonials-transition"

const ContactsPage = (props) => (
  <Layout>
    <YMInitializer accounts={[61638022]} options={{ defer: true }} />
    <SEO
      title="Контакты"
      keywords={["Контакты компании Империя студия мебели", "Контакты студии мебели Империя", "ООО Империя студия мебели", "ООО Империя студия мебели контакты", "Империя студия мебели СПб", "Контакты компании Империя студия мебели", "Контакты Империя СПб", "Империя контакты", "Империя СПб", "Империя контакты СПб"]}
      description="Контакты компании Империя студия мебели в СПб - телефоны, адреса салонов, e-mail, соцсети"
    />

    <h1 className="text-center">Контакты в Санкт-Петербурге</h1>

    <Container fluid className="bg-light">
      <Row className="py-5 px-5 bg-white">
        <Col md={4}>
          <h3 className="contacts-title text-center">Телефоны</h3>
          <a href="tel:+7-999-005-1925"><p className="font-weight-light text-center text-dark">
            +7 (999) 005-19-25 <br />
            (МЦ «Мебельный Континент»)
          </p></a>
          <a href="tel:+7-921-908-0881"><p className="font-weight-light text-center text-dark">
            +7 (921) 908-08-81 <br />
            (МЦ «Мебель Холл»)
          </p></a>
        </Col>
        <Col md={4}>
          <h3 className="contacts-title text-center">E-mail</h3>
          <a href="mailto:9080881@mail.ru"><p className="font-weight-light text-center text-dark text-decoration-none">9080881@mail.ru</p></a>
        </Col>
        <Col md={4}>
          <h3 className="contacts-title text-center">Шоурумы</h3>
          <p className="font-weight-light text-center">
            пл. Карла Фаберже, д.8, Мебельный центр «Мебель Холл», 3 этаж,
            Секция 336
          </p>
          <p className="font-weight-light text-center">
            ул. Варшавская, д. 3, Мебельный центр «Мебельный Континент» - 2
            корпус, 3 этаж, секции 305, 307
          </p>
        </Col>
      </Row>
      <h2 className="text-center order-form-heading" id="zakaz">
        Сделать заказ
      </h2>
      <Row className="justify-content-center pb-5">
        <OrderForm />
      </Row>
      <h2 className="text-center bg-white mb-0 pb-2 pt-5">Наши сотрудники</h2>
      <section className="bg-white">
      <Container className="bg-white">
      <Row className="py-5 row bg-white">
        <Col
          sm={12}
          md={6}
          lg={6}
          xl={4}
          className="align-self-center text-center my-2"
          style={{ minHeight: '595px', }}
        >
          {/* <Figure className="text-left">
            <Img
              fixed={props.data.maria.childImageSharp.fixed}
            />
            <Figure.Caption>
              <b>Мария</b> <br />
              МЦ «Мебельный Континент»
            </Figure.Caption>
          </Figure> */}
          <SlideTestimonial
        alt='Анастасия Рейнбуш'
        image={DesignerImgOne}
        imageRadius='50%'
        title='Анастасия Рейнбуш'
        subtitle='Дизайнер'
        testimonial={'Занимаюсь дизайном интерьеров и мебели с 2015 года. Проектирую мебель даже во сне. Ключевое в работе – найти для клиента лучшее решение'}
        backgroundColor='#f9f9f9'
        shadow={5}
      />
        </Col>
        <Col
          sm={12}
          md={6}
          lg={6}
          xl={4}
          className="align-self-center text-center my-2"
          style={{ minHeight: '595px', }}
        >
          {/* <Figure className="text-left">
            <Img
              fixed={props.data.anna.childImageSharp.fixed}
            />
            <Figure.Caption>
              <b>Анна</b> <br />
              МЦ «Мебельный Континент»
            </Figure.Caption>
          </Figure> */}
          <SlideTestimonial
        alt='Имя Фамилия'
        image={DesignerImgTwo}
        imageRadius='50%'
        title='Юлия Ионова'
        subtitle='Дизайнер'
        testimonial={<ShowMoreText
          /* Default options */
          lines={4}
          more='Подробнее'
          less='Скрыть текст'
          anchorClass='text-dark underline-text'
          expanded={false}
          width='318'
        >{'В команду «ИМПЕРИЯ» пришла с 20-летним опытом работы в мебельной индустрии. Опыт и знания, полученные за это время, позволяют мне создавать и реализовывать любые проекты. Нахожусь в непрерывном образовательном потоке: посещаю тематические мастер-классы, вебинары. Вдохновения ищу в процессе знакомства с культурой других стран и разносторонней культурой России. И конечно, основным источником вдохновения и идей остается архитектура города Санкт-Петербурга. К каждому проекту подхожу грамотно и творчески. Особенную радость и удовлетворение приносит, когда вся последующая работа моих коллег по производству и сервису приводит к лучшему результату в целом и оставляет желание у клиента вернуться и рекомендовать нашу мебель и компанию. Однажды, прочитав цитату Оскара Уайльд „У меня непритязательный вкус: мне вполне достаточно самого лучшего.“ сделала девизом в своей жизни и работе. Запланировали обновить интерьер новой, стильной, а главное качественной мебелью, с удовольствием помогу. Жду вас в МЦ «Мебель Холл».'}
        </ShowMoreText>}
        backgroundColor='#f9f9f9'
        shadow={5}
      />
        </Col>
        <Col
          sm={12}
          md={6}
          lg={6}
          xl={4}
          className="align-self-center text-center my-2"
          style={{ minHeight: '595px', }}
        >
          {/* <Figure className="text-left">
            <Img
              fixed={props.data.maria.childImageSharp.fixed}
            />
            <Figure.Caption>
              <b>Мария</b> <br />
              МЦ «Мебельный Континент»
            </Figure.Caption>
          </Figure> */}
          <SlideTestimonial
        alt='Елена Чурсина'
        image={DesignerImgThree}
        imageRadius='50%'
        title='Елена Чурсина'
        subtitle='Дизайнер'
        testimonial={'Закончила Международную Школу Дизайна в Санкт Петербурге. Работаю во многих графических программах, рисую скетчи на айпаде. Люблю придумывать новую функциональную и красивую мебель. Продумываю все детали).'}
        backgroundColor='#f9f9f9'
        shadow={5}
      />
        </Col>
      </Row>
      </Container>
      </section>
      <h2 className="text-center pt-5">Мы на карте</h2>
      <Row className="mt-5 pb-5">
        <Col md={12} lg={6} className="align-self-center">
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1001.0255104251031!2d30.310445221140203!3d59.88150229788991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469630738a571b5b%3A0xe07d95023d785a38!2sVarshavskaya%20Ulitsa%2C%203%2C%20Sankt-Peterburg%2C%20196128!5e0!3m2!1sen!2sru!4v1585298390714!5m2!1sen!2sru"
            width="100%"
            className="map-iframe mx-auto"
            height="400"
          />
        </Col>
        <Col md={12} lg={6} className="align-self-center">
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471.66880608821793!2d30.436267827570177!3d59.93441969726947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469631fd19351237%3A0x9cdfa02be3caa760!2zUGxvc2hjaGFkJyBLYXJsYSBGYWJlcnpoZSwgOCDQmtCe0KDQn9Cj0KEgMiwgU2Fua3QtUGV0ZXJidXJnLCAxOTUyNzc!5e0!3m2!1sen!2sru!4v1585298814657!5m2!1sen!2sru"
            width="100%"
            className="map-iframe mx-auto"
            height="400"
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactsPage

export const contactsPageQuery =
  graphql`
    query {
      anna: file(relativePath: { eq: "office_1_1.jpg" }) {
        childImageSharp {
          fixed(quality: 100, width: 290) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      svetlana: file(
        relativePath: { eq: "office_2_1.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 100, width: 290) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      svetlanatwo: file(
        relativePath: { eq: "office_2_2.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 100, width: 290) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      maria: file(
        relativePath: { eq: "office_1_1 – 2.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 100, width: 290) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `;